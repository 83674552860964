@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import './base/settings';
@import './base/settings.modal';
@import './base/variables-bootstrap';

// components
@import './components/login.scss';
@import './components/register.scss';
@import './components/navbar';
@import './components/dashboard';
@import './components/loader';
@import './components/gralinfo';
@import './components/teamemers';
@import './components/portfolio';
@import './components/companygralinfo';
@import './components/sidebar';
// @import './components/executivesummary'
@import './components/valuepreposition';
@import './components/leadershipteam';
@import './components/fundind';
@import './components/dropFile';
@import './components/financial';
@import './components/search';
@import './components/publicprofileview';
@import './components/home';
@import './components/savedInvestorscomponente';
@import './components/matchactivity';
@import './components/notifications';

// ESTILOS DEL PROFILE INVESTOR

@import './components/investorhome';
@import './components/savedcompaniescomponente';
@import './components/searchcompanies';
@import './components/investorpublicprofile';


// ESTILOS DE CHAT
@import './components/chat';

// ESTILOS FOOTER
@import './components/footer';
