._about-us{
        
    .btn-save {
        background-color: $primary;
        border-color: $primary;
        width: 150px;
        border-radius: 8px;
    }
    .alineacionBtn{
        text-align: end;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
            text-align: center;

        }

    }
    .textoRequerido{
        font-weight: 500;
        margin-right: 15px;
    }
    @media only screen and (max-width: 740px) {
        .btn-save {
            margin-top: 15px;
        }
        .textoRequerido{
            margin-right: 0px;
        }
    }
}
