._register {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .main {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;

    .data {
      max-width: 500px;
      margin: auto;
      padding: 40px;
      width: 100%;

      h4 {
        font-weight: 600;
      }

      label {
        font-weight: 400;
        color: #222;
      }

      input,
      select {
        // box-shadow: 0 0 20px #ccc;
        border: 1px solid #ccc;
        border-radius: 8px;
      }


      //new lines fa eye
      .form-control.pass.is-invalid{
        padding-right: calc(1.5em + 2.75rem) !important;
      }
      .form-control.pass.is-invalid ~ .icon-font{
        margin-right: 2rem;
      }

      .btn-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 13px;
          a{
            
          }
        }
        button {
          width: auto;
          padding: 5px 30px;
          border-radius: 50px;
          border: none;
          color: white;
          font-weight: 600;
          background-color: $primary;
        }
      }

      .btn-container-return {
        button {
          width: auto;
          padding: 5px 30px;
          font-weight: 600;
        }
        
      }
    }
  }

  .modificador1 {
    margin-right: 15px;
  }
  .image {
    background-image: url("../../assets/images/register.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    img {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 200px;
    }
  }

  .main section {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  ._register .main section {
    width: 100%;
  }
  ._register .main .image {
    display: none;
  }
}

.container-password {
  position: relative;
}

.container-password .tooltip{
  opacity: 1;
  right: 0%;
  top: 4.8rem;
}

.container-password .tooltip .tooltip-arrow::before{
  border-right-color: #fff;
  border-right: 0.4rem solid transparent;
  border-top: 0.4rem solid transparent;
  border-left: 0.4rem solid transparent;
  border-bottom: 0.4rem solid #fff;
  top: -0.8rem;
  left: 0.7rem;
}

.container-password .tooltip .tooltip-inner{
  background-color: #fff;
  color: var(--primary);
  box-shadow: 0px 0px 7px -3px;;
}