$primary: #3816b0;
$secondary: #25dac6;
$danger: #e74c3c;
$gris: #afafaf;

// Buttons Config Global
$btn-font-weight: 600;
$btn-border-width: 2px;

// $btn-border-radius: 0.4rem;
// $btn-border-radius-sm: 0.4rem;
// $btn-border-radius-lg: 0.4rem;

// Container Config Global
$grid-gutter-width: 20px !default;
$container-max-widths: (
  sm: 1366px,
  md: 1366px,
  lg: 1366px,
  xl: 1366px,
  xxl: 1366px,
);

// Source mixin
@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
  max-width: 1366px;
}

.container-dashboard {
  @include make-container($padding-x: 45px);
  padding-bottom: 100px;
  padding-top: 70px;
  @media screen and (max-width: 1150px) {
    padding: 70px 45px 140px 45px !important;
  }
  @media screen and (max-width: 600px) {
   padding-left: 0px !important;
   padding-right: 0px !important;

  }

}
.container-navbar {
  @include make-container($padding-x: 0);

  @media screen and (min-width: 1411px) {
    padding-left: 56px;
    padding-right: 45px;
  }
}

.container-footer {
  @include make-container($padding-x: 0);  
  padding: 1rem 3rem 1rem 3rem !important;

  @media screen and (min-width: 1411px) {
    padding-left: 56px;
    padding-right: 45px;
  }
}

* {
  font-family: "Poppins", "Arial";
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-item:not(:first-of-type) {
  // border-top: normal;
  border: 1px solid #afafaf;
}

.content-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  align-items: stretch;
}

.icon-font {
  position: absolute;
  background-color: #fff;
  right: 7px;
  top: 50%;
  transform: translate(-0%, -50%);
  cursor: pointer;
}

.text-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  font-family: inherit;
}

// MODAL
.modalDialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  transition: opacity 400ms ease-in;
  pointer-events: none;
  &:target {
    opacity: 1;
    pointer-events: auto;
  }
  & > div {
    width: 460px;
    position: relative;
    margin: 10% auto;
    padding: 5px 50px 13px 50px;
    border-radius: 10px;
    background: #fff;
    transition: opacity 400ms ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    select {
      width: 250px;
    }
    @media screen and (max-width: 460px)  {
      width: 95%;
    }
  }
  .close {
    background: #fff;
    line-height: 25px;
    font-size: 22px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 7px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 12px;
    border: 2 solid $primary;
    &:hover {
      background: $primary;
      color: #fff !important;
    }
  }
  .circulo {
    width: 60px;
    height: 60px;
    font-size: 35px;
    border-radius: 100px;
    color: #fff;
    background-color: #00dfc567;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.border-img {
  border: 1px solid #bababa;
  display: block;
  height: 100px;
  max-width: 100px;
}

.disable-mode {
  pointer-events: none;
  opacity: 0.7;
}