._PublicProfileView {
    //------- CON URL --------
    .img-main-cover-publicProfile {
        position: relative;
        display: flex;
        background-size: cover;
        background-color: transparent;
        height: 195px;
        margin-bottom: 60px;
    }

    .img-redonda-main-publicProfile {
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 150px;
        left: 2%;
        bottom: -25px;
        border: 1px solid #bababa;
    }

    & .primer-parrafo {
        font-weight: 700;
        word-spacing: 3px;
    }
    & .fa-linkedin {
        color: #0a66c2;
        font-size: 30px;
    }
    & .fa-globe,
    & .fa-map-marker,
    & .fa-industry,
    & .fa-users,
    & .fa-calendar-alt {
        color: #b381eb;
    }

    & .espaciado-entre-palabras {
        word-spacing: 3px;
    }

    & .contenedor-botones {
        text-align: end;
        @media only screen and (max-width: 768px) {
            text-align: center;
        }
        button {
            width: 245px;
        }
    }

    & .btn-outline-primary {
        margin-bottom: 15px;
        padding: 7px 50px;
        border: 2px solid $primary;

    }
    & .estilos-boton {
        width: 200px;
    }
    & .segundo-parrafo {
        text-align: justify;
    }
    & .contenedor-titulo {
        margin: 35px 0;
        & .titulo-seccion {
            color: #8d8d8d;
            // width: 190px;
            min-width: max-content;
            margin-right: 1rem;
        }
        .line {
            width: 100%;
            height: 1px;
            background-color: $gris;
        }
    }

    .enlaces-estilos{
        text-decoration: none;
        color: #000;

    }
    // estilos de seccion financials
    & .tarjetas-financials {
        width: 100%;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;
        padding: 10px 1rem;
        height: 80%;


        & .precio-financial {
            font-weight: 700;
            font-size: 16px;

        }
    }
    & .financial-section .accordion-body {
        padding: 0 !important;
    }
    & table {
        border: 1px solid rgba(224, 222, 222, 0.877);
        & thead {
            background-color: #D7D0EF;
            .encabezados {
                text-align: center;
            }

            .encabezados{
                th {border-bottom: 2px solid transparent;}
            }
        }
        & td {
            vertical-align: middle;
            padding: 15px 10px;
        }
    }
    & .modificador-celda-table {
        // min-width: 125px;
        text-align: end;
        width: max-content;
    }

    //estilos de seccion value proposition

    & .accordion-header .accordion-button.collapsed{
        box-shadow: none;
    }

    & .accordion-header .accordion-button {
        background-color: #f3f3f3;
        font-weight: 600;
        box-shadow: none;
    }

    & .accordion-header {
        background-color: #f3f3f3;
    }

    & .contenedor-cards-leadership-v2{
        flex-wrap: wrap !important;
        // justify-content: space-around !important;

        & .card-leadership-member{
            width: 100% !important;
            margin: unset !important;
            padding: 20px 10px;
        }
    }

    // estilos de leadership team
    & .contenedor-cards-leadership {
        display: flex;
        justify-content: center;

        // justify-content: space-between;
        & .card-leadership-member {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            margin: 15px 10px;
            line-height: 25px;
            text-align: center;
            & .contenedor-imagen {
                width:  130px;
                height: 130px;
                & img {
                    width: 100%;
                    height: 100%;
                    border-radius: 150px;
                }
            }
        }
    }
    @media only screen and (max-width: 1150px) {
        .contenedor-titulo{
            justify-content: center;
            .titulo-seccion{
                position: absolute;
                background: #fff;
                padding: 0 5px;
            }
        }
    }
    @media only screen and (max-width: 965px) {
        & .contenedor-cards-leadership {
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
            align-items: center;

        }
    }
    // estilos de FUnding
    & .parrafo-funding {
        text-align: justify;
        margin-bottom: 45px;
    }
    .contenedor-mobile, .contenedor-mobile2{
        display: none;
    }
    @media only screen and (max-width: 767px) {
        .visualidad-desktop{
            display: none;
        }
        .contenedor-mobile{
            display: flex;
            flex-direction: column;
            align-items: center;

            
        }
        .img-redonda-main-publicProfile{
            left: inherit;

        }
        .img-main-cover-publicProfile{
            justify-content: center;
        }

    }
    @media only screen and (max-width: 405px) {
        .visualidad-desktop2{
            display: none;
        }
        .contenedor-mobile2{
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 28px;
            text-align: center;

        }

    }
}
