// .contenedor {
//   // padding: 0 45px;

//   .__dashboard {
//     &__wrapper {
//       position: relative;
//     }

//     .logo-white {
//       width: 31px;
//       cursor: pointer;
//     }
//     col.menu-desktop {
//          display: none !important;
//         }
  
//     // @media screen and (max-width: 1090px)  {
//     //  col .menu-desktop {
//     //    display: none !important;
//     //   }

//     // }

//   }
// }

.container-dashboard{
    @media screen and (max-width: 1150px)  {
           .menu-desktop {
             display: none !important;
            }
            .__dashboard__wrapper {
              width: 100%;
            }
      
          }

  .btn-profile-back{
    text-decoration: none;
    color: #3816b0;
    cursor: pointer;
  }  
}