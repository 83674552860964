._drop_file {
    width: 100%;
  .recuadro_drop_here {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgb(228, 228, 228);
    border: 3px dashed $gris;
    border-radius: 15px;
    height: 250px;
    margin: 50px 0;

    & .fa-upload {
      font-size: 70px;
      margin-bottom: 20px;
    }
    & span {
      font-size: 20px;
    }
    & .btn-file {
      position: absolute;
      width: 150px;
      border-radius: 8px;
      top: 220px;
    }
  }

  .btn-file {
    position: absolute;
    width: 150px;
    border-radius: 8px;
  }

  .overlay {
    background-color: rgba($color: $primary, $alpha: 0.9);
    border: 3px dashed white;
    color: #ffffff;
  }
}
