._login {
  width: 100%;
  height: 100vh;
  position: relative;
  a {
    text-decoration: none;
  }
  .c-purple {
    color: $primary;
    font-weight: 500;
  }

  label {
    font-weight: 500;
  }
  .content {
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    h4 {
      text-align: center;
      margin: 10px 0 40px;
      font-weight: 600;
    }

    .logo {
      max-width: 350px;
      width: 100%;
    }

    input {
      // box-shadow: 0 0 20px #ccc;
      border: 1px solid #bababa;
      padding: 20px;
      &::-webkit-input-placeholder {
        color: #bababa;
        opacity: 1; /*Change the opacity between 0 and 1*/
      }
    }

    .btn-container {
      width: 100%;
      text-align: right;
    }

    .btn-container button {
      width: auto;
      padding: 7px 70px;
      font-weight: 600;
    }

    .content-input {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      position: relative;
      align-items: stretch;
    }
  }
}
