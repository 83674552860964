@import url("../../../theme/colors.css");

.modibtnMain {
  background-color: var(--primary);
  border-color: var(--primary);
  width: 150px;
}

.modiDivBtnMain {
  text-align: end;
}

/* .title-check {
  color: var(--primary);
} */

.nav-tabs .nav-item .nav-link {
  color: #999
}

.nav-tabs .nav-item .nav-link.active {
  color: var(--primary);
}

/* ------- CON URL -------- */
.imgMainCover {
  position: relative;
  display: flex;
  /* background-image: url("../../../assets/images/background_main.jpg"); */
  background-size: cover;
  background-color: transparent;
  height: 195px;
  margin-bottom: 60px;
}

/* -------- SIN URL ---------- */
/* .imgMainCover{
  position: relative;
  display: flex; 
}*/
.imgPortada {
  width: 100%;
  height: 250px;
}

.imgRedondaMain {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 150px;
  left: 2%;
  bottom: -25px;
  border: 1px solid #bababa;
}

.contentDot {
  position: absolute;
  left: 1%;
  bottom: -33px;

  margin-left: 105px;
  /* -webkit-margin-start: 50px; */
  /* display: flex;
  justify-content: end;
  width: 150px; */
}

.contentDot2 {
  position: absolute;
  right: 2%;
  bottom: -22px;
}

.imgRedondaLapiz {
  width: 25px;
  height: 25px;
}

.pasosIncompletos {
  color: rgb(177, 177, 177);
  font-weight: 500;
}

.pasosCompletos {
  color: var(--secondary);
  font-weight: 500;
}

.contenedorProgreso {
  border: 2px solid #f1f1f1;
  border-radius: 15px;
  padding: 15px 25px;
  margin-bottom: 30px;
}

.textoArribaProgreso {
  font-weight: 600;
  font-size: 16px;
  text-align: justify;
}

.alineacionBtn {
  text-align: end;
}

.progress-bar {
  background-color: var(--secondary);
}

.modifAltura {
  margin-top: 30px;
}

.nav {
  flex-wrap: nowrap !important;
}

.swal2-styled.swal2-confirm {
  color: #fff !important;
  background-color: #3816b0 !important;
  border-color: #3816b0 !important;
  width: 126px;
  height: 48px;
  border-radius: 0.25rem !important;

}

.swal2-styled.swal2-deny {
  border: 2px solid transparent !important;
  background-color: transparent !important;
  border-color: #3816b0 !important;
  color: #3c2f88 !important;
  width: 126px;
  height: 48px;
}

.swal2-title {
  font-family: "Poppins", "Arial" !important;
  font-size: 16px !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.swal2-close {
  color: #3816b0 !important;
  border: 2px solid #3816b0 !important;
  border-radius: 30px !important;
  width: 20px !important;
  height: 20px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 25px !important;
  margin: 10px !important;
  padding: 10px !important;
  font-weight: 600 !important;
  font-family: "Poppins", "Arial" !important;


}

.swal2-html-container {
  /* text-align: justify; */
  display: flex !important;
  text-align: justify;
}


.contenedor-btns .estilo-btn {
  padding: 5px 40px !important;
}

@media only screen and (max-width: 1060px) {
  .modif-wrap {
    flex-wrap: wrap !important;

  }
}

@media only screen and (max-width: 768px) {
  .alineacionBtn {
    text-align: center;
    margin: 5% 0;
  }
}

@media only screen and (max-width: 991px) {
  .parrafo-salto-de-linea {
    display: none;
  }
}

@media only screen and (max-width: 925px) {
  .nav {
    flex-wrap: wrap !important;
  }
}

/* ESTILOS PARA ACOMODAMIENTO EN MOBILE DE IMG DE PERFIL */

@media only screen and (max-width: 600px) {

  .imgRedondaMain,
  .contentDot {
    left: inherit !important;
  }

  .imgMainCover {
    justify-content: center;
  }
  .contentDot {

    margin-left: 50px;
  
  }
}

/* ESTILOS PARA CAJA DE BOTONES DE LA CARD */

@media only screen and (max-width: 495px) {
  .contenedor-botones-investor {
    flex-direction: column;
    align-items: center;


  }

  .contenedor-botones-investor .btn-save2 {
    margin-top: 15px;
  }


  .contenedor-botones-investor .btn {
    width: 175px;
    margin-left: 0 !important;
    margin-bottom: 10px;


  }


}

@media screen and (max-width: 767px) {
  .contenedor-btn {
    justify-content: center !important;

  }
}

@media screen and (max-width: 1150px) {
  /* .main-contenedor-progreso .accordion-mobile{
    display: block !important;
  } */

  .main-contenedor-progreso .tabs-desktop,
  .main-contenedor-progreso .tabs-desktop~.tab-content {
    display: none !important;
  }
}

@media screen and (min-width: 1151px) {
  .main-contenedor-progreso .accordion-mobile {
    display: none !important;
  }
}