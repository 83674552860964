._loader {
  z-index: 9999;
  background: rgba($color: #160849, $alpha: 0.95);
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: progress;
  // img {
  //   width: 40%;
  //   margin-left: 550px;

  // }
  // .loader {
  //   position: relative;
  //   width: 2.5em;
  //   height: 2.5em;
  // }
  // .loader:before,
  // .loader:after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   display: block;
  //   width: 0.5em;
  //   height: 0.5em;
  //   border-radius: 0.25em;
  //   transform: translate(-50%, -50%);
  // }
  // .loader:before {
  //   animation: before 2s infinite;
  // }
  // .loader:after {
  //   animation: after 2s infinite;
  // }

  // @keyframes before {
  //   0% {
  //     width: 0.5em;
  //     box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
  //       -1em 0.5em rgba(111, 202, 220, 0.75);
  //   }
  //   35% {
  //     width: 2.5em;
  //     box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75),
  //       0 0.5em rgba(111, 202, 220, 0.75);
  //   }
  //   70% {
  //     width: 0.5em;
  //     box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75),
  //       1em 0.5em rgba(111, 202, 220, 0.75);
  //   }
  //   100% {
  //     box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
  //       -1em 0.5em rgba(111, 202, 220, 0.75);
  //   }
  // }
  // @keyframes after {
  //   0% {
  //     height: 0.5em;
  //     box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
  //       -0.5em -1em rgba(233, 169, 32, 0.75);
  //   }
  //   35% {
  //     height: 2.5em;
  //     box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75),
  //       -0.5em 0 rgba(233, 169, 32, 0.75);
  //   }
  //   70% {
  //     height: 0.5em;
  //     box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75),
  //       -0.5em 1em rgba(233, 169, 32, 0.75);
  //   }
  //   100% {
  //     box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
  //       -0.5em -1em rgba(233, 169, 32, 0.75);
  //   }
  // }

  // .loader {
  //   position: absolute;
  //   top: calc(50% - 1.25em);
  //   left: calc(50% - 1.25em);
  // }
  .spinner {
    
    width: 2.5em;
    height: 2.5em;
  
    position: absolute;
    top: calc(50% - 1.25em);
    left: calc(50% - 1.25em);    // margin: 100px auto;
    .double-bounce1, .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      
      -webkit-animation: bounce 2.0s infinite ease-in-out;
      animation: bounce 2.0s infinite ease-in-out;
    }
    
    .double-bounce2 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }
    
    @-webkit-keyframes bounce {
      0%, 100% { -webkit-transform: scale(0.0) }
      50% { -webkit-transform: scale(1.0) }
    }
    
    @keyframes bounce {
      0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
      } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
    }
  }
  

}
