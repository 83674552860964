._footer{
    a{
        text-decoration: none;
    }
    .container-footer{
        display: flex;
        justify-content: center;

        @media screen and (max-width: 675px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
    @media screen and (max-width: 675px) {
        .container-footer{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}