.dropdown:focus, .dropdown-toggle:focus {
    outline: none !important;
    box-shadow: unset !important;
}

.icon-notifications{
    .dropdown-toggle::after{
        display: none;
    }
    .custom-dropdown-menu{
        background-color: transparent;
        border: none;
    }

    @media screen and (max-width: 767px) {
        .custom-dropdown-menu{
            position: fixed !important;
            width: 100%;
            transform: translate3d(0, 59px, 0px) !important;
        }
    }

    @media screen and (max-width: 400px) {
        .custom-dropdown-menu{
            transform: translate3d(0, 53px, 0px) !important;
        }
    }
}
._notifications {
    border: 2px solid #eeeeee;
    box-shadow: 0 5px 15px rgb(172, 172, 172);
    border-radius: 10px;
    position: absolute;
    right: 22%;
    background-color: white;
    margin: 0 12px;
    z-index: 8;
    overflow: auto;
    max-height: 400px;
    width: 400px;

    .notification-icon {
        color: $primary;
        cursor: pointer;
    }

    .bg-gray {
        background-color: #eeeeee;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;

        // .title {
        //     display: flex;
        //     align-items: center;
        // }
    }

    &__rows {
        width: 100%;

        .notf-middle{
            color: #3816b0;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid rgba(0,0,0,.125);
        img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
        span {
            font-size: 13px;
            line-height: 13px;
            font-weight: 500;
        }
        i {
            cursor: pointer;
        }
    }

    &__row.not_viewed{
        background-color: #f0f1f2;
    }

    @media screen and (max-width: 477px) {
        width: 300px !important;
        right: -15%;
    }
    @media screen and (max-width: 385px) {
        width: 285px !important;
        right: -25%;
    }
}

@media screen and (max-width: 767px) {
    ._notifications {
        width: 100% !important;
        left: 0 !important;
        right: 0 !important;
        margin: unset !important;
    }
}

.dropdown-user{

    .dropdown-toggle{
        display: flex !important;
        flex-direction: row;
        align-items: center;
        padding: 0 !important;
    }

    .dropdown-toggle::after{
        display: none !important;
    }

    .fa.fa-angle-down{
        color: #3816b0;
    }

    .custom-dropdown-menu-user{
        transform: translate3d(0, 61px, 0px) !important;
        text-align: center;
    }

    @media screen and (max-width: 400px) {
        .custom-dropdown-menu-user{
            transform: translate3d(0, 59px, 0px) !important;
        }
    }
}