._matchactivity {
    .contenedor-titulo {
        margin-top: 30px;

        & .titulo-seccion {
            color: #8d8d8d;
            // width: 178px;
            min-width: max-content;
            margin-right: 0.5rem;

        }

        .line {
            width: 100%;
            height: 1px;
            background-color: $gris;
        }
    }

    .card-content {
        .fila {
            div:last-child {
                justify-content: flex-end;
            }

            .titulo-img {
                color: $primary;
                font-weight: 700;

                //added
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 170px;
                cursor: pointer;
                //end added
            }
        }

        &_image {
            width: 100px;

            // margin-bottom: 5px;
            img {
                width: 100px;
                height: 100px;
                border-radius: 100px;
                cursor: pointer;
            }
        }

        &_modificador-txt {
            color: $primary;
            font-weight: 700;
            cursor: pointer;
        }

        .modificador1 {
            margin-right: 10px;
        }

        .modificador2 {
            width: 150px !important;
        }

        .modificador3 {
            width: 210px !important;
        }

        // .btn {
        //     width: 100px;
        // }
        .txt-containers {
            font-weight: 600;
            display: flex;
            align-items: center;

            .circulo {
                width: 25px;
                min-width: 25px;
                height: 25px;
                min-height: 25px;
                border-radius: 100px;
                color: #fff;
                background-color: #00dfc5;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            // .circulo-disabled {
            //     width: 25px;
            //     height: 25px;
            //     border-radius: 100px;
            //     color: #fff;
            //     background-color: #888;
            //     margin-right: 10px;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            // }
        }

        .txt-containers.disabled {
            opacity: 0.3 !important;

            .circulo {
                background-color: #888 !important;
            }
        }

        .btn-containers {
            text-align: end;
        }

        .state-containers {
            font-weight: 500;
            text-align: center;
        }

        .contenedor-botones {
            button {
                padding: 5px 25px;
            }
        }
    }

    @media only screen and (max-width: 780px) {

        .contenedor-info-card {
            flex-direction: column;
        }
        .state-containers, .btn-containers{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .btn-containers {
            display: flex;
            flex-direction: column;
        }
        .boton-notFit .modificador1{
            margin-right: 0 !important;
        }
    }

    // ESTILOS PARA CONTENEDOR BTNS DEL MODAL "THIS IS A FIT"
    .contenedor-btns {
        display: flex;
        @media only screen and (max-width: 400px) {
            flex-direction: column;
            align-items: center;
            .boton-cancel-modal{
                margin-right: 0 !important;
                margin-bottom: 10px;
            }
        }
    }


    @media only screen and (max-width: 767px) {
        .contenedor-btns-final{
            flex-direction: column;
            button{
                margin-bottom: 10px !important;
            }
        }
    }
    @media only screen and (max-width: 1150px) {
        .contenedor-titulo {
            justify-content: center;

            .titulo-seccion {
                position: absolute;
                background: #fff;
                padding: 0 5px;
            }
        }
    }

    @media only screen and (max-width: 851px) {
        .alineacion-texto-mobile {
            justify-content: center !important;

            div {
                text-align: center !important;
                justify-content: center !important;

            }
        }
    }

    @media only screen and (max-width: 460px) {
        .contenedor-botones {
            flex-direction: column;

            .modificador1 {
                margin-right: 0px;
                margin-bottom: 10px;
            }
        }


    }
}