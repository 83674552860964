._SearchCompanies {
    .fa-linkedin {
        color: #0a66c2;
        font-size: 30px;
    }
    .contenedor-titulo {
        margin-top: 30px;
        & .titulo-seccion {
            color: #8d8d8d;
        }
    }
    .accordion-header {
        background-color: #e4e4e4;
        .accordion-button {
            background-color: #e4e4e4;
        }
    }
    // ESTILOS DE IMAGEN DE PORTADA Y DE PERFIL
    .imgMainCover {
        position: relative;
        display: flex;
        background-image: url("../../assets/images/background_main.jpg");
        background-size: cover;
        background-color: transparent;
        height: 195px;
        margin-bottom: 60px;
    }
    // ESTILOS DE LA SECCION DE INFORMACIÓN DE COMPAÑIA
    .investor-company-info {
        .primer-parrafo {
            font-weight: 700;
            word-spacing: 3px;
        }

        .fa-globe,
        .fa-map-marker,
        .fa-industry,
        .fa-users,
        .fa-calendar-alt {
            color: #b381eb;
        }

        .espaciado-entre-palabras {
            word-spacing: 3px;
        }

        .contenedor-botones {
            text-align: end;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: end;
            @media only screen and (max-width: 768px) {
                align-items: start;
            }
        }

        .btn-outline-primary {
            margin-bottom: 15px;
        }
        .estilos-boton {
            width: 155px;
        }
        .segundo-parrafo {
            text-align: justify;
        }
        .contenedor-titulo {
            margin: 30px 0;
            .titulo-seccion {
                color: #b381eb;
            }
        }
    }

    // ESTILOS DE SECCION DE FINANCIALS
    & .tarjetas-financials {
        width: 100%;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;
        padding: 30px 1rem;

        & .precio-financial {
            color: #000;
        }
    }
    & .financial-section .accordion-body {
        padding: 0 !important;
    }
    & table {
        border: 1px solid rgba(224, 222, 222, 0.877);
        & thead {
            background-color: #dcbcff;
        }
        & td {
            vertical-align: middle;
            padding: 15px 10px;
        }
    }
    & .modificador-celda-table {
        min-width: 125px;
    }

    //  ESTILOS DE SECCION LEADERSHIP TEAM
    .company-leadership {
        .contenedor-cards-leadership {
            display: flex;
            flex-wrap: wrap;

            // justify-content: space-between;
            & .card-leadership-member {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 15px 10px;
                line-height: 25px;
                & .contenedor-imagen {
                    width: 100px;
                    height: 100px;
                    & img {
                        width: 100%;
                        height: 100%;
                        border-radius: 150px;
                    }
                }
            }
        }
        @media only screen and (max-width: 965px) {
            & .contenedor-cards-leadership {
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}
