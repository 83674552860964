._chat {

    .messages-side .btn-back{
        display: none;
        outline: 0;
        box-shadow: none;
    }
    @media screen and (max-width: 767px)  {

        .contacts-side{
            display: none;
        }

        .contacts-side.active{
            display: block;
        }

        .messages-side .btn-back{
            display: block !important;
            background: transparent;
            border: 0;
            color: #3816b0;
            font-size: 1.5rem;
        }

        .contacts-side.active ~ .messages-side{
            display: none !important;
            background-color: red !important;
        }
        
    }

    height: 100%;

    // background: #7F7FD5;
    // background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    // padding: 15px 0;

    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 18px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: #25dac583;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background-color: #25dac6;
    }



    // .chat {
    //     margin-top: auto;
    //     margin-bottom: auto;
    // }

    .card {
        height: 600px;
        border-radius: 0.3rem !important;
        // background-color: rgba(0, 0, 0, 0.4) !important;
        background-color: #f0f1f2;
        border: 0 !important;
    }

    .contacts_body {
        padding: 0.75rem 0 !important;
        overflow-y: auto;
        white-space: nowrap;
    }

    .msg_card_body {
        overflow-y: auto;
        background-color: #f0f1f2;
    }

    .card-header {
        border-radius: 0.3rem !important;
        border-bottom: 0 !important;
        background-color: #d8d8d9;
    }

    .card-footer {
        border-radius: 0 0 15px 15px !important;
        border-top: 0 !important;

        .input-group{
            flex-wrap: inherit;
        }

        .input-group-append{
            border-left: 1px solid #d8d8d9;
        }
    }

    .container {
        align-content: center;
    }

    .search {
        border-radius: 15px 0 0 15px !important;
        // background-color: rgba(0, 0, 0, 0.3) !important;
        border: 0 !important;
        // color: white !important;
    }

    .search:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    .type_msg {
        // background-color: rgba(0, 0, 0, 0.3) !important;
        border: 0 !important;
        // color: white !important;
        height: 60px !important;
        overflow-y: auto;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .type_msg:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }

    .attach_btn {
        border-radius: 15px 0 0 15px !important;
        background-color: rgba(0, 0, 0, 0.3) !important;
        border: 0 !important;
        color: white !important;
        cursor: pointer;
        height: 60px;
        align-items: end;
    }

    .send_btn {
        border-radius: 0 15px 15px 0 !important;
        // background-color: rgba(0, 0, 0, 0.3) !important;
        background-color: #fff;
        border: 0 !important;
        // color: white !important;
        color: #3816b0;
        cursor: pointer;
        height: 60px;

    }

    .search_btn {
        border-radius: 0 15px 15px 0 !important;
        // background-color: rgba(0, 0, 0, 0.3) !important;
        background-color: #fff;
        border: 0 !important;
        // color: white !important;
        cursor: pointer;
        height: 38px;

    }

    .contacts_card{
        .card-header{
            padding: 1rem 1rem !important;

            .input-group{
                
            }
        }
    }

    .contacts {
        list-style: none;
        padding: 0;

        .active, li:hover  {
            background-color: #d8d8d9;
        }

        .active{
            .user_info{
                span{
                    color: #25dac6 !important;
                }
            }
        }
    }

    .contacts li {
        width: 100% !important;
        padding: 10px;
        // margin-bottom: 15px !important;
        cursor: pointer;
    }

    .user_img {
        height: 70px;
        width: 70px;
        border: 2px solid #f5f6fa;


    }

    .user_img_msg {
        height: 40px;
        width: 40px;
        // border: 2px solid #f5f6fa;
        border: 1px solid #bababa;
    }

    .img_cont {
        position: relative;
        .contenedor-img{
            height: 60px;
            width: 60px;
        }

        img {
            border-radius: 50%;
            // width: 100%;
            width: 60px;
            height: 60px;
            // border: 2px solid #f5f6fa;
            border: 1px solid #bababa;

        }
    }

    .img_cont_msg {
        height: 40px;
        width: 40px;
    }

    .online_icon {
        position: absolute;
        height: 15px;
        width: 15px;
        background-color: #4cd137;
        border-radius: 50%;
        bottom: 0.2em;
        right: 0.4em;
        border: 1.5px solid white;
    }

    .offline {
        background-color: #c23616 !important;
    }

    .user_info {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
    }

    .user_info span {
        font-size: 18px;
        // color: white;
        color: #3816b0;
    }

    .user_info p {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.6);
    }

    .video_cam {
        margin-left: 50px;
        margin-top: 5px;
    }

    .video_cam span {
        color: white;
        font-size: 20px;
        cursor: pointer;
        margin-right: 20px;
    }

    .msg_cotainer {
        word-break: break-word;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        border-radius: 25px;
        // background-color: #82ccdd;
        background-image: linear-gradient(0deg, #5877e46b, #7956dd6b);
        padding: 10px;
        position: relative;

        p:last-child{
            margin-bottom: 0 !important;
        }
    }

    .msg_cotainer_send {
        word-break: break-word;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
        border-radius: 25px;
        // background-color: #78e08f;
        background-image: linear-gradient(0deg, #5ac2e46b, #25dac66b);
        padding: 10px;
        position: relative;

        p:last-child{
            margin-bottom: 0 !important;
        }
    }
    

    .msg_time {
        position: absolute;
        left: 0;
        bottom: -15px;
        // color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 10px;
        min-width: 110px;
        text-align: left;
    }

    .msg_time_send {
        position: absolute;
        right: 0;
        bottom: -15px;
        // color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 10px;
        min-width: 110px;
        text-align: right;
    }

    .msg_head {
        position: relative;
    }

    #action_menu_btn {
        position: absolute;
        right: 10px;
        top: 10px;
        color: white;
        cursor: pointer;
        font-size: 20px;
    }

    .action_menu {
        z-index: 1;
        position: absolute;
        padding: 15px 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border-radius: 15px;
        top: 30px;
        right: 15px;
        display: none;
    }

    .action_menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .action_menu ul li {
        width: 100%;
        padding: 10px 15px;
        margin-bottom: 5px;
    }

    .action_menu ul li i {
        padding-right: 10px;

    }

    .action_menu ul li:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.2);
    }

    @media(max-width: 576px) {
        .contacts_card {
            margin-bottom: 15px !important;
        }
    }

    .custom-input-group{
        .form-label{
            display: none;
        }
    }

    .custom-input-group div:first-child{
        flex: 1 1 auto;
    }

}