._InvestorPublicProfile {
    .fa-linkedin {
        color: #0a66c2;
        font-size: 30px;
    }
    .contenedor-titulo {
        margin: 45px 0;
        & .titulo-seccion {
            color: #8d8d8d;
            min-width: max-content;
            margin-right: 1rem;
        }
        .line {
            width: 100%;
            height: 1px;
            background-color: $gris;
        }
    }
    .accordion-header {
        background-color: #e4e4e4;
        .accordion-button {
            background-color: #e4e4e4;
        }
    }
    // ESTILOS DE IMAGEN DE PORTADA Y DE PERFIL
    .imgMainCover {
        position: relative;
        display: flex;
        background-image: url("../../assets/images/background_main.jpg");
        background-size: cover;
        background-color: transparent;
        height: 195px;
        margin-bottom: 60px;
    }
    // ESTILOS DE LA SECCION DE INFORMACIÓN DE COMPAÑIA
    .investor-company-info {
        .primer-parrafo {
            // font-weight: 700;
            word-spacing: 3px;
        }

        .fa-globe,
        .fa-map-marker,
        .fa-industry,
        .fa-users,
        .fa-calendar-alt,
        .fa-user-tie, 
        .fa-dollar-sign {
            color: #b381eb;
        }

        .espaciado-entre-palabras {
            word-spacing: 3px;
        }

        .contenedor-botones {
            text-align: end;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            @media only screen and (max-width: 768px) {
                align-items: center;
            }
        }

        .btn-outline-primary {
            margin-bottom: 15px;
        }
        .estilos-boton {
            width: 230px;
        }
        .segundo-parrafo {
            text-align: justify;
        }
        .contenedor-titulo {
            margin: 30px 0;
            .titulo-seccion {
                color: #b381eb;
            }
        }
    }

    // ESTILOS DE SECCION DE CARDS TOP
    .contenedor-cards-financial {
        display: flex;
        justify-content: center;
        & .tarjetas-financials {
            width: 350px;
            text-align: center;
            font-weight: 700;
            margin-bottom: 15px;
            padding: 30px 1rem;

            & .precio-financial {
                color: #000;
            }
        }

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            .tarjetas-financials {
                width: 100%;
            }
        }
    }

    //  ESTILOS DE SECCION LEADERSHIP TEAM
    .investor-public-leadership{
        .contenedor-cards-leadership {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            & .card-leadership-member {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 15px 10px;
                line-height: 25px;
                text-align: center;
                & .contenedor-imagen {
                    width:  130px;
                    height: 130px;
                    & img {
                        width: 100%;
                        height: 100%;
                        border-radius: 150px;
                    }
                }
            }
        }
        @media only screen and (max-width: 965px) {
            & .contenedor-cards-leadership {
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column;

            }
        }
    }

    // ESTILOS DE PORTFOLIO

    .card-content {
        &_image {
            width: 100px;
            img {
                width: 100px;
                height: 100px;
                border-radius: 100px;
            }
        }

        // &_text {
            
        // }
    }
    .mobilePort{
        display: none;
    }
    @media only screen and (max-width: 575px) {
        .card-content_text{
            text-align: center;
        }
        .mobilePort{
            display: block;
        }
        .desktopPort{
            display: none;
        }
        .alineando-text-portfolio{
            justify-content: center;
        }
    }
    .card-content_text
    {
        line-height: 20px;

    } 

    .contenedor-mobile, .contenedor-mobile2{
        display: none;
    }


    @media only screen and (max-width: 767px) {
        .visualidad-desktop{
            display: none;
        }
        .contenedor-mobile{
            display: flex;
            flex-direction: column;
            align-items: center;
            
        }

    }
    @media only screen and (max-width: 480px) {
        .visualidad-desktop2{
            display: none;
        }
        .contenedor-mobile2{
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 28px;
            text-align: center;

        }

    }
    @media only screen and (max-width: 1150px) {
        .contenedor-titulo{
            justify-content: center;
            .titulo-seccion{
                position: absolute;
                background: #fff;
                padding: 0 5px;
            }
        }
    }
}
