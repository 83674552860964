$modal-backdrop-opacity: 0.25;
$modal-content-border-color: transparent;
$modal-content-bg: white;
$modal-backdrop-bg: white;

// @Modal Match Activity
.modal-common {
  .modal-content {
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(221, 221, 221);
  }
}
