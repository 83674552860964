._search {
  width: 100%;

  .title {
    color: #8d8d8d !important;
    margin-bottom: 60px;

    span {
      width: 135px;
    }
    .title-not-there {
      width: 220px;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: $gris;
    }
  }

  .card-content {
    .modificador-orden{
      flex-wrap: nowrap;
      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        margin: 0 auto;

      }
      
    }
    &_image {
        width: 100px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 100px;
      }
    }

    &_text {
      h3 {
        font-weight: 600;
        font-size: 18px;
        color: $primary;
      }
      h5 {
        font-weight: 400;
        font-size: 16px;
      }
      .modificador-pholosofy{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      i {
        width: 25px;
        // color: $primary;
        color: #b381eb;
      }
    }

    .actions {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .range {
        font-weight: 600;
      }
    }
  }
  .fondo-gris{
    .card{
      background-color: #F0F1F2 !important;
    }
  }
  .contenedor-titulo .titulo-seccion{
    width: 130px;
  }
  @media only screen and (max-width: 1150px) {
    .contenedor-titulo{
        justify-content: center;
        .titulo-seccion{
            position: absolute;
            background: #fff;
            padding: 0 5px;
            width: inherit;

        }
    }
}
@media screen and (max-width: 991px) {
  .alineacion-mobile{
    text-align: center;
  }
}
}

