._value-preposition{

    .accordion-button:not(.collapsed)::after {
        background-image: url('../../assets/icons/minus-circle-solid.svg');
        transform: rotate(-180deg);
    }

    .accordion-button::after {
        background-image: url('../../assets/icons/plus-circle-solid.svg');
    }

    .accordion .accordion-item{
        border: 0.1px solid #afafaf;
        border-left: 0px;
        border-right: 0px;
    }
    .icono-header{
        margin-right: 5px;
        color: $gris;
    }
    .accordion-header {
        padding: 10px 0;

    }
    .accordion-body{
        // & input{
        //     padding: 25px 10px;
        // }
    
        & div {
            margin-bottom: 25px;
        }
    }
    .btn-save {
        background-color: $primary;
        border-color: $primary;
        width: 150px;
        border-radius: 8px;
    }
    .alineacionBtn{
        text-align: end;
        @media screen and (max-width: 767px) {
            text-align: center;

        }
    }
    .textoRequerido{
        font-weight: 500;
        margin-right: 15px;
    }
    @media only screen and (max-width: 740px) {
        .btn-save {
            margin-top: 15px;
        }
        .textoRequerido{
            margin-right: 0px;
        }

        .accordion-body{
            textarea{
                min-height: calc(1.5em + 6.75rem + 2px);
            }
        }
        
    }
}