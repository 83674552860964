._home {
    .card-body{
        width: 100%;
      }
    & .contenedor-top-cards {
        & .tarjeta {
            color: #ffff;
            //   margin: 10px 15px;
            border: 0;
            text-align: center;
            border-radius: 10px !important;
            font-size: 19px;

            & .contenedor-txt-bottom {
                font-size: 35px;

                & i {
                    color: rgba(212, 212, 212, 0.692);
                }
            }

            & .card-body .card-text {
                margin-bottom: 1px;
            }
        }

        & .color1 {
            background-image: linear-gradient(0deg, rgba(88, 119, 228, 1) 0%, rgba(121, 86, 221, 1) 100%);
        }

        & .color2 {
            background-image: linear-gradient(0deg, rgba(90, 194, 228, 1) 0%, rgba(37, 218, 198, 1) 100%);
        }

        & .color3 {
            background-image: linear-gradient(0deg, rgba(255, 177, 44, 1) 0%, rgba(255, 203, 81, 1) 100%);
        }
    }

    & .contenedor_middle_featured,
    & .contenedor_bottom_saved,
    .contenedor-match-activity {
        .contenedor-titulo {
            // margin-top: 30px;
            margin: 35px 0;

            & .titulo-seccion {
                color: #8d8d8d;
                // width: 205px;
                min-width: max-content;
                margin-right: 0.5rem;
            }

            .line {
                width: 100%;
                height: 1px;
                background-color: $gris;
            }
        }

        & .contenedorFeautredItem {
            & .card {
                align-items: center;
                font-weight: 500;
                width: 100%;

                & img {
                    width: 100px;
                    border-radius: 95px;
                    cursor: pointer;
                }

                & .card-title {
                    text-align: center;
                    color: $primary;
                    cursor: pointer;
                }

                & i {
                    color: #b381eb;
                    font-size: 15px;
                    width: 15px;
                }
            }

            & .contenedor_carta_relativ {
                display: flex;
                position: relative;
                justify-content: center;
               
                @media screen and (min-width: 768px) {
                    height: 100%;
                }

                & .fa-bookmark {
                    position: absolute;
                    left: 80%;
                    top: 4%;
                    z-index: 2;
                    font-size: 35px;
                    color: #86fcf4;
                }
                @media screen and (max-width: 767px) {
                    .card-body {
                        text-align: center;
                    }
                }
                
            }
        }

        & .contenedor-btn {
            text-align: end;

            @media screen and (max-width: 767px) {
                text-align: center;

            }
        }
    }

    .contenedor-match-activity {

        .cards-match-container {
            & .card {
                align-items: center;
                font-weight: 500;
                width: 100%;
                text-align: center;

                & img {
                    width: 100px;
                    border-radius: 95px;
                    cursor: pointer;
                }

                & .card-title {
                    text-align: center;
                    color: $primary;
                    cursor: pointer;
                }

                .color-status {
                    color: #ff9900;
                }
            }
        }
    }

    @media only screen and (max-width: 1150px) {
        .contenedor-titulo {
            justify-content: center;

            .titulo-seccion {
                position: absolute;
                background: #fff;
                padding: 0 5px;
            }
        }
    }
}