._teammember {
  .imgRedonda {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .estilos-header {

    & button {
      border-radius: 15px !important;
      border-top: 1px solid #afafaf;

    }

    & .centrar-header {
      display: flex;
      align-items: center;
      // justify-content: center;
      // padding-left: 2.5rem;
      width: 100%;
    }
  }

  .textoCabecera {
    color: #000;
    font-weight: 500;
    width: 250px;

  }

  .contenedorImgCabecera {
    width: 65px;
    height: 65px;
    min-width: 65px;
    min-height: 65px;
    margin-right: 15px;
  }

  .imgRedondaCabecera {
    width: 100%;
    height: 100%;
    border-radius: 150px;
  }

  .cajaImg {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .contentDot3 {
    position: absolute;
    left: 63%;
    // top: 120px;
    top: 75%;
  }

  .btnTeamembers {
    background-color: #ffff;
    border-color: $primary;
    color: $primary;
    border-width: 2px;
    font-weight: 500;
    border-radius: 8px;
    width: 160px;
  }

  .alineacionMember {
    text-align: center;
  }

  .alineacionTeam {
    text-align: center;
    margin-bottom: 45px;
  }

  .modifMargenBajo {
    margin-bottom: 30px;
    border-radius: 15px !important;
    border-color: #afafaf;
    border-width: 1px;
  }

  .card:first-of-type {
    border-bottom: 1 !important;
  }

  .btn-save {
    background-color: $primary;
    border-color: $primary;
    width: 150px;
    border-radius: 8px;
  }

  .textoRequerido {
    font-weight: 500;
    margin-right: 15px;
  }

  @media only screen and (max-width: 992px) {
    .cajaImg {
      margin-bottom: 25px;
    }

    .contentDot3 {
      left: 55%;
    }
  }

  @media only screen and (max-width: 740px) {
    .btn-save {
      margin-top: 15px;
    }

    .textoRequerido {
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 466px) {
    .centrar-header {
      padding-left: 0 !important;
    }
    .accordion-button{
      padding-right: 1.75rem !important;
    }
  }

  @media only screen and (max-width: 400px) {
    .contenedorImgCabecera {
      width: 46px;
      height: 45px;
      min-width: 46px;
      min-height: 45px;
      margin-right: 5px;
    }
  }



}