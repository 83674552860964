@import url('../../../theme/colors.css');

.container img {
  width: 200px;
  position: relative;
  top: 30px;
}

.wrapper {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 550px) {
  .wrapper {
    top: 50%;
  }
}

.wrapper h2 {
  color: var(--primary);
  font-size: 2em;
  font-weight: 300;
}

.wrapper input {
  box-shadow: 0 0 20px #ccc;
  border: none;
  border-radius: 8px;
}

.btn-wrapper button {
  width: auto;
  padding: 5px 30px;
  border-radius: 50px;
  border: none;
  color: white;
  font-weight: 600;
  background-color: var(--primary);
}

.reset-password .tooltip{
  opacity: 1;
  right: -48%;
  top: 2.7rem;
}

.reset-password .tooltip .tooltip-arrow::before{
  border-right-color: #fff;
}

.reset-password .tooltip .tooltip-inner{
  background-color: #fff;
  color: var(--primary);
  box-shadow: 0px 0px 7px -3px;;
}

@media screen and (max-width: 992px) {

  .reset-password .tooltip .tooltip-arrow::before{
    border-right: 0.4rem solid transparent;
    border-top: 0.4rem solid transparent;
    border-left: 0.4rem solid transparent;
    border-bottom: 0.4rem solid #fff;
    top: -0.8rem;
    left: 0.7rem;
  }

  .reset-password .tooltip{
    right: 0%;
    top: 4.7rem;
  }
}