@import url('../../../theme/colors.css');

.main-container img {
  width: 200px;
  position: relative;
  top: 30px;
}

.wrapper {
  width: 100%;
  max-width: 540px;
  padding: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 550px) {
  .wrapper {
    top: 50%;
  }
}

.wrapper h2 {
  color: var(--primary);
  font-size: 2em;
  font-weight: 300;
}

.wrapper input {
  box-shadow: 0 0 20px #ccc;
  border: none;
  border-radius: 8px;
}

.btn-wrapper button {
  width: auto;
  padding: 5px 30px;
  /* border-radius: 50px; */
  /* border: none; */
  /* color: white; */
  /* font-weight: 600; */
  /* background-color: var(--primary); */
}

.wrapper .resend {
  margin-top: 10px;
  color: #111;
  font-weight: 500;
  font-size: 16px;
  color: var(--primary);
  text-decoration: underline;
  text-align: end;
  cursor: pointer;
}
