._financial{
    table {

    input {
        text-align: right;
    }
    
    border: 1px solid rgba(224, 222, 222, 0.877);
    &   thead{
        background-color: rgba(224, 222, 222, 0.877);
        .encabezados{
            text-align: center;

        }
    }
    & td {
        vertical-align: middle;
        padding: 15px 10px;

        & label{
            display: none;
        }
    }
}
    .modificador-celda-table{
        min-width: 125px
    }
    .contenedor_inputs{
        margin-bottom: 65px;
        input:placeholder-shown {
            text-overflow: ellipsis;
          }
    }

    .btn-save {
        background-color: $primary;
        border-color: $primary;
        width: 150px;
        border-radius: 8px;
    }
 @media only screen and (max-width: 740px) {
        .btn-save {
            margin-top: 15px;
        }
        .textoRequerido{
            margin-right: 0px;
        }
        
      }
}