._portfolio{

        
    .imgRedonda1 {
        width:100px !important;
        height:100px;
        border-radius:150px;
    }
    .estilos-header{
        & button{
            border-radius: 15px !important;
            border-top: 1px solid #afafaf;

        }
        & .centrar-header{
            display: flex;
            align-items: center;
            // justify-content: center;
            // padding: 0 37px;

            width: 100%;
          }

          @media only screen and (max-width: 466px) {
            .centrar-header {
              padding-left: 0 !important;
            }
            .accordion-button{
              padding-right: 2rem !important;
            }
          }
    }
    
    
    .textoCabecera{
        color: #000;
        font-weight: 500;
        width: 250px;

    }
    .contenedorImgCabecera{
        width: 65px;
        height: 65px;
        margin-right: 15px;
        min-width: 65px;
        min-height: 65px;
    }
    .imgRedondaCabecera{
        width: 100%;
        height: 100%;
        border-radius: 150px;
    }
    .cajaImg1{
        text-align: center;
    }

    .cajaImg {
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
      }

    .contentDot4 {
        position: absolute;
        left: 60%;
        top: 80px;
    
    }
    .alineacionPortfolio{
        text-align: center;
    }

    .btnPortfolio{
        background-color: #ffff;
        border-color: $primary;
        color: $primary;
        border-width: 2px;
        font-weight: 500;
        border-radius: 8px;
        width: 160px;

    }
    .btn-save {
        background-color: $primary;
        border-color: $primary;
        width: 150px;
        border-radius: 8px;
    }
    .textoRequerido{
        font-weight: 500;
        margin-right: 15px;
    }

    .modifMargenBajo{
        margin-bottom: 30px;
        border-radius: 15px !important;
        border-color: #afafaf;
        border-width: 1px;
      }

    @media only screen and (max-width: 992px) {
        .cajaImg {
        margin-bottom: 25px;
    
        }
        .contentDot4 {
        left: 53%;
        }
    }
    @media only screen and (max-width: 740px) {
        .btn-save {
            margin-top: 15px;
        }
        .textoRequerido{
            margin-right: 0px;
        }
      }
}