._sidebar {
  // border-radius: 10px;
  width: 100%;
  min-height: 100%;
  background-color: #f0f1f2;
  padding: 20px;
  min-width: 170px;
  padding: 20px 20px 100% 20px;
  border-radius: 0.3rem;
  @media screen and (max-width: 1150px)  {
    background-color: #f0f1f2;


   }

  .active-link {
    color: $secondary;
    i, span, div {
      color: $secondary !important;
    }
  }
  ._sidebar__items-list{
    span{
      color: $primary;
    }
  }

  .logo-white {
    width: 20px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  ul {
    padding: 0;
  }

  li {
    list-style: none;
    padding: 15px 10px;

    .icon-w {
      width: 20px;
    }

    .child-item {
      padding-left: 60px !important;
    }

    & > div {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    i {
      font-size: 20px;
    }
    span {
      color: black;
      font-weight: 500;
    }
  }

  &__items-list {
    color: $primary;
  }

  .search-sidebar {
    .actions button {
      min-width: 165px;
    }
  }
  .logo-active{
    fill: $secondary;
  }
  .logo-inactive{
    fill: $primary;
  }
}
