@media screen and (max-width: 1150px) {
  .__body-overflow-hidden {
    overflow: hidden;
  }
}

.__nav-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // height: 80px

  .burguer-menu{
    @media screen and (min-width: 1150px) {
      display: none !important;
    }
  }

  .logo img {
    height: 50px;
    width: auto;

  }

  .search-bar {
    width: 100%;
    max-width: 400px;

    input {
      width: auto;
      background-color: transparent;
      border-color: $primary;
      border-radius: 50px;
      border: 2px solid $primary;
      border-left: none;
    }

    .input-group {
      width: 100%;
      max-width: 650px;

      i {
        color: $primary;
      }
    }

    .input-group-prepend {
      border-radius: 50px;
      border: none;
      background-color: transparent;

      span {
        border-radius: 50px 0px 0px 50px;
        border: 2px solid $primary;
        background-color: transparent;
        height: 40px;
        border-right: none;
      }
    }
  }

  .feature-profile {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-profile {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      // background-image: url("https://www.verizon.com/about/sites/default/files/page-component-image/hans-vestberg-photo.jpg");
      background-size: contain;
      // background-repeat: no-repeat;
      background-repeat: inherit;
      background-position: center center;
      border: 1px solid #bababa;
    }

    .desc-profile p {
      margin-bottom: 0px;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .btn-cerrar-sesion {
    button {
      background-color: #F8F9FA;
      // border-color: #F8F9FA;
      font-size: 25px;
      color: black;

      .show>.btn-primary.dropdown-toggle {
        color: #000 !important;
        background-color: #F8F9FA !important;
        // border-color: #F8F9FA !important;
      }

    }

    div.dropdown-menu {
      min-width: max-content !important;
    }


    .dropdown:focus,
    .dropdown-toggle:focus {
      outline: none !important;
      box-shadow: unset !important;
    }

  }

  @media screen and (max-width: 635px) {

    .desc-profile {
      display: none !important;
    }
  }

  @media screen and (max-width: 545px) {
    .logo {
      margin: 0 5px;

      img {
        height: 36px;
      }
    }

    .desc-profile {
      display: none !important;
    }

    .image-profile {
      margin: 0 !important;
    }

    .dropdown-toggle {
      padding: 0 5px !important;
    }

  }

  @media screen and (max-width: 420px) {
    .logo img {
      height: 28px;
    }

    .fa-comment-alt,
    .fa-bell,
    .fa-bars {
      font-size: 18px !important;
    }

    .feature-profile .image-profile {
      height: 40px;
      width: 40px;
    }

    @media screen and (max-width: 300px) {
      .feature-profile .image-profile {
        height: 35px;
        width: 35px;
      }
    }
  }
}

.navbar ~ .contenedor-menu {
  display: block;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 99;
  height: 100%;
  overflow-y: scroll;
  background-color: #f0f1f2;
  padding-top: 60px;

  @media screen and (min-width: 1150px) {
    display: none !important;
  }

  @media screen and (max-width: 450px) {
    padding-top: 45px;
  }

  li {
    display: block;
    margin: 0;
    padding: 10px;
  }

  li:hover {
    background-color: #3816bd1f;
  }
}

.estilos-navbar {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;

  @media screen and (max-width: 450px) {
    padding: 5px 15px !important;

  }
}