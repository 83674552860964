._savedCompaniescomponente{

    
    // .contenedor-titulo {
    //     & .titulo-seccion {
    //         color: #8d8d8d;
    //     }
    //     .line {
    //         width: 100%;
    //         height: 1px;
    //         background-color: $gris;
    //     }
    // }
    .contenedor-titulo {
        margin-top: 30px;
        & .titulo-seccion {
            color: #8d8d8d;
            // width: 220px;
            min-width: max-content;
            margin-right: 0.5rem;
        }
    }
         .line {
            width: 100%;
            height: 1px;
            background-color: $gris;
        }
    .card-content {
        &_image {
            width: 100px;
            img {
                width: 100px;
                height: 100px;
                border-radius: 100px;
                // border: #f0f1f2 solid 1px;
                border: #bababa solid 1px;
                cursor: pointer;
            }
        }

        &_text {
            h3 {
                font-weight: 600;
                font-size: 18px;
                color: $primary;
                cursor: pointer;
            }
            h5 {
                font-weight: 400;
                font-size: 16px;
            }
            
            i {
                width: 25px;
                color: #b381eb;
            }
        }

        .actions {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .range {
                font-weight: 600;
            }
        }
    }
    .contenedor-botones {
        text-align: end;
        @media only screen and (max-width: 768px) {
            text-align: center;
        }
        .estilos-boton {
            max-width: 200px;
        }
        .modificador1{
            margin-right: 15px;
            @media only screen and (max-width: 468px) {
                margin-bottom: 10px;
            }
        }
    }
    @media only screen and (max-width: 1150px) {
        .contenedor-titulo{
            justify-content: center;
            .titulo-seccion{
                position: absolute;
                background: #fff;
                padding: 0 5px;
            }
        }
    }
    @media only screen and (max-width: 575px) {
        .card-content_text{
            text-align: center;
        }
        .contenedor-botones {
            display: flex;
            flex-direction: column;
            align-items: center;
            .modificador1 {
                margin-right: 0 !important;
            }
        }
    }
    
}