._funding {
  .alinear_boton {
    text-align: end;
    @media screen and (max-width: 767px) {
      text-align: center;

  }
    & .btn-save {
      background-color: $primary;
      border-color: $primary;
      width: 150px;
      border-radius: 8px;
    }
  }
}
